import type { InventoryResource } from '@repo/api-gw-sdk';
import type { PaginationState, SortingState } from '@tanstack/react-table';
import { useState } from 'react';

import {
  accountId,
  backupRegions,
  id,
  lastBackup,
  resourceId,
  sourceRegion,
  subnets,
  tags,
  vaults,
  backupStorageSize,
  vpc,
  dateDiscovered,
} from '@/components/queryBuilder/properties';
import { Table } from '@/components/table';
import { useColumns } from '@/components/table/hooks';
import { useNavigationQuery } from '@/contexts/useNavigationQuery';
import { useDAL } from '@/data/dal';
import { createCombineCondition, type Condition } from '@/types/advanceFilter';

import InventoryMultiInstanceMenu from './InventoryMultiInstanceMenu';
import { getColumns } from './inventoryListTableColumns';

interface InventoryListTableProps {
  openFilters?: boolean;
  showFiltersBar: boolean;
  enableColumnSelection: boolean;
  enableFilters: boolean;
  sorting: SortingState;
  selectedIds?: string[];
  pagination: PaginationState;
  baseFilter?: Condition;
  allowedColumns?: string[];
  disableSelection?: boolean;
  onSortingChange: (sortField: string, desc: boolean) => void;
  onPaginationChange: (value: PaginationState) => void;
  onRowSelectionChange?: (
    entities: InventoryResource[],
    onRowContentChange?: () => Promise<void>
  ) => void;
}

export const InventoryListTable = (props: InventoryListTableProps) => {
  const dal = useDAL();
  const [selectedEntities, setSelectedEntities] = useState<InventoryResource[]>(
    []
  );
  const { filter: columnFilters } = useNavigationQuery();
  let filter = columnFilters || props.baseFilter;
  if (props.baseFilter && columnFilters) {
    filter = createCombineCondition([props.baseFilter, columnFilters]);
  }

  const {
    body,
    isLoading,
    mutate: mutateAllInventory,
  } = dal.inventory.list(props.pagination, props.sorting[0], filter);

  const columns = useColumns(getColumns);
  const allowsColumns = props.allowedColumns?.length
    ? columns.filter((column) =>
        props.allowedColumns?.includes(column.accessorKey || '')
      )
    : columns;

  const onRowSelectionChange = (
    entities: InventoryResource[],
    onRowContentChange?: () => Promise<void>
  ) => {
    setSelectedEntities(entities);
    props.onRowSelectionChange?.(entities, onRowContentChange);
  };

  return (
    <Table
      enableFilters={props.enableFilters}
      entityLabel='resources'
      disableSelection={props.disableSelection}
      openFilters={props.openFilters}
      showFiltersBar={props.showFiltersBar}
      data={body?.resources || []}
      testId='inventory-table'
      isMultiSelect={true}
      multiSelectActionButton={
        <InventoryMultiInstanceMenu entities={selectedEntities} />
      }
      columns={allowsColumns}
      hiddenColumns={[
        id.name,
        tags.name,
        accountId.name,
        resourceId.name,
        vpc.name,
        subnets.name,
        lastBackup.name,
        sourceRegion.name,
        backupRegions.name,
        vaults.name,
        backupStorageSize.name,
        dateDiscovered.name,
      ]}
      stickyHeader
      // pinnedColumns={[backupStatus.name, resourceName.name]}
      sorting={props.sorting}
      isLoading={isLoading}
      onSortingChanged={(state) =>
        props.onSortingChange(state[0].id, state[0].desc)
      }
      onRowSelectionChange={onRowSelectionChange}
      onRowContentChange={async () => {
        await mutateAllInventory();
      }}
      pagination={{
        ...props.pagination,
        pageSizesOptions: [25, 50, 100],
        recordsCount: body?.pagination.recordsCount,
        onChange: props.onPaginationChange,
      }}
      enableColumnSelection={props.enableColumnSelection}
      selectedIds={props.selectedIds}
    />
  );
};

interface InventoryListProps {
  baseFilter?: Condition;
  disableSelection?: boolean;
  onRowSelectionChange?: (entities: InventoryResource[]) => void;
}

export const InventoryList = (props: InventoryListProps) => {
  return (
    <InventoryListTable
      baseFilter={props.baseFilter}
      disableSelection={props.disableSelection}
      enableColumnSelection
      enableFilters={false}
      showFiltersBar={false}
      sorting={[{ id: 'id', desc: false }]}
      pagination={{ pageIndex: 0, pageSize: 25 }}
      onPaginationChange={() => {}}
      onSortingChange={() => {}}
    />
  );
};
