import {
  Box,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  Divider,
  Button,
  Dialog,
} from '@mui/material';
import type { InventoryResource } from '@repo/api-gw-sdk';
import { DataClassification } from '@repo/api-gw-sdk';
import { useState } from 'react';

import { Icon } from '@/components/shared/icon';
import { useDAL } from '@/data/dal';
import { DataClasses } from '@/data/inventory/data';

function dataClassState(
  entities: InventoryResource[]
): Map<DataClassification, { intermediate: boolean; checked: boolean }> {
  const dataClasses = Object.values(DataClassification).filter(
    (x) => x !== DataClassification.Unspecified
  );
  const dataClassesSelected = dataClasses.map((dataClass) => {
    const allEntitiesHaveDataClass = entities.every((entity) =>
      entity.dataClasses?.includes(dataClass)
    );
    const someEntitiesHaveDataClass = entities.some((entity) =>
      entity.dataClasses?.includes(dataClass)
    );
    return {
      intermediate: !allEntitiesHaveDataClass && someEntitiesHaveDataClass,
      checked: allEntitiesHaveDataClass,
      dataClass,
    };
  });
  return new Map(dataClassesSelected.map((x) => [x.dataClass, x]));
}

export function DataClassEditor({
  open,
  onClose,
  entities,
}: {
  open: boolean;
  onClose: () => void;
  entities: InventoryResource[];
}) {
  const [dataClasses, setDataClasses] = useState(dataClassState(entities));
  const [updating, setUpdating] = useState(false);
  const dal = useDAL();

  const onSave = () => {
    setUpdating(true);
    const selected = [...dataClasses.entries()]
      .filter(([, state]) => state.checked)
      .map(([key]) => key);

    const ids = entities.map((x) => x.id);
    void dal.inventory.dataClasses
      .update(ids, selected)
      .then(() => {
        for (const entity of entities) {
          entity.dataClasses = selected;
          entity.isDataClassesOverridden = true;
        }
      })
      .finally(onClose);
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open}>
      <Box
        sx={{
          m: 0,
          p: 0,
        }}
      >
        <Stack className='h-full'>
          <Stack
            justifyContent='space-between'
            direction='row'
            alignItems='center'
            padding='16px 24px'
          >
            <Stack alignItems='center' direction='row'>
              <div className='flex flex-col'>
                <Typography className='font-semibold'>
                  Override data classes
                </Typography>
              </div>
            </Stack>
            <div className='flex gap-4'>
              <Icon
                onClick={onClose}
                iconClass='material-symbols-close-rounded'
              />
            </div>
          </Stack>
          <Box
            sx={{
              paddingLeft: '40px',
              paddingRight: '40px',
              maxWidth: '630px',
            }}
          >
            <Typography sx={{ lineHeight: '20px' }} className='font-light'>
              {`Eon won't automatically classify data classes for the selected resources. This could change the applied backup policies and controls.`}
            </Typography>
          </Box>
          <Divider sx={{ marginTop: '24px', marginBottom: '16px' }} />
          <Box sx={{ paddingLeft: '16px', paddingRight: '40px' }}>
            {Object.values(DataClassification)
              .filter((x) => x !== DataClassification.Unspecified)
              .map((x) => (
                <div key={x}>
                  <FormControlLabel
                    title={DataClasses[x].title}
                    className='w-full'
                    control={
                      <Checkbox
                        indeterminate={dataClasses.get(x)?.intermediate}
                        checked={dataClasses.get(x)?.checked}
                        sx={{ padding: '10px 10px 10px 30px' }}
                        size='small'
                        onChange={() => {
                          const state = dataClasses.get(x);
                          if (!state) {
                            return;
                          }
                          const checked = !state.checked;
                          const intermediate = false;
                          setDataClasses(
                            new Map([
                              ...dataClasses,
                              [x, { checked, intermediate }],
                            ])
                          );
                        }}
                      />
                    }
                    label={x}
                  />
                </div>
              ))}
          </Box>
          <Stack
            justifyContent='flex-end'
            direction='row'
            marginTop='12px'
            padding='16px 24px'
          >
            <Box sx={{ marginRight: '12px' }}>
              <Button onClick={onClose}>
                <Typography
                  className='font-semibold'
                  sx={{ color: 'var(--mui-palette-primary-main)' }}
                >
                  Cancel
                </Typography>
              </Button>
            </Box>
            <Box>
              <Button
                onClick={onSave}
                variant='outlined'
                disabled={[...dataClasses.values()].some((x) => x.intermediate)}
              >
                {!updating ? (
                  <Typography
                    className='font-semibold'
                    sx={{ color: 'var(--mui-palette-primary-main)' }}
                  >
                    Override
                  </Typography>
                ) : (
                  <i className='line-md-loading-loop w-[20px] h-[20px]' />
                )}
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
}
