import { Button } from '@mui/material';
import { BackupStatus, type InventoryResource } from '@repo/api-gw-sdk';
import React, { forwardRef, useState } from 'react';

import OptionMenu from '@/@core/components/option-menu';
import type { OptionType } from '@/@core/components/option-menu/types';
import { AppsEditor } from '@/components/panels/instance/tabs/classificationPanels/appsEditor';
import { DataClassEditor } from '@/components/panels/instance/tabs/classificationPanels/dataClassEditor';
import { EnvironmentEditor } from '@/components/panels/instance/tabs/classificationPanels/environmentEditor';
import { RestartApplicationDetection } from '@/components/panels/instance/tabs/classificationPanels/restartApplicationDetection';
import { RestartAutomaticDataClassClassification } from '@/components/panels/instance/tabs/classificationPanels/restartAutomaticDataClassification';
import { RestartAutomaticEnvironmentDetection } from '@/components/panels/instance/tabs/classificationPanels/restartAutomaticEnvironmentDetection';
import { Icon } from '@/components/shared/icon';
import { useRoles } from '@/contexts/useRoles';
import { useDAL } from '@/data/dal';

import InventoryBulkActionDialog from './inventoryBulkActionDialog';
import InventoryBulkPolicyAssignmentDialog from './inventoryBulkPolicyAssignmentDialog';

const richIcon = (icon: string) => (
  <i className={`${icon} w-[20px] h-[20px] mr-[12px]`} />
);

const containedItemText = (text: string) => (
  <>
    <span className='mr-[12px]'>{text}</span>
    <i className='material-symbols-chevron-right-rounded w-[20px] h-[20px] ml-auto' />
  </>
);

export default function InventoryMultiInstanceMenu(props: {
  entities: InventoryResource[];
}) {
  const { entities } = props;
  const [excludeFromBackupDialogOpen, setExcludeFromBackupDialogOpen] =
    useState(false);
  const [
    cancelBackupExclusionsDialogOpen,
    setCancelBackupExclusionsDialogOpen,
  ] = useState(false);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [dataClassesDiagOpen, setDataClassesDiagOpen] = useState(false);
  const [envDiagOpen, setEnvDiagOpen] = useState(false);
  const [appDialogOpen, setAppDialogOpen] = useState(false);
  const [resumeAppsDialogOpen, setResumeAppsDialogOpen] = useState(false);
  const [resumeDataClassesDialogOpen, setResumeDataClassesDialogOpen] =
    useState(false);
  const [resumeEnvDialogOpen, setResumeEnvDialogOpen] = useState(false);

  const { isAuthorized } = useRoles();
  const dal = useDAL();

  const excludeFromBackup = () => {
    setExcludeFromBackupDialogOpen(false);
    void dal.inventory.resources.updateExclusion({
      resourceIds: entities.map((entity) => entity.id),
      excluded: true,
    });
  };

  const cancelBackupExclusions = () => {
    setCancelBackupExclusionsDialogOpen(false);
    void dal.inventory.resources.updateExclusion({
      resourceIds: entities.map((entity) => entity.id),
      excluded: false,
    });
  };

  const actions: OptionType[] = [
    // {
    //   text: 'Restore',
    //   icon: 'material-symbols-settings-backup-restore-rounded',
    // },
    // { divider: true },
    {
      text: 'Backup Management',
      groupHeader: true,
    },
    {
      text: 'Assign to backup policy',
      icon: richIcon('material-symbols-bookmark-add-outline'),
      menuItemProps: {
        disabled: !isAuthorized('update:policies'),
        onClick: () => setAssignDialogOpen(true),
      },
    },
    // { text: 'Exclude from backup policy', icon: 'material-symbols-remove-rounded', iconClassName='text-textPrimary' },
    // { divider: true },
    // {
    //   text: 'Take snapshot',
    //   icon: 'material-symbols-backup-outline-rounded',
    //   disabled: !isAuthorized('create:jobs'),
    // },
    // { text: 'Convert snapshots' },
    // { text: `Delete Eon's snapshots` },
    // { divider: true },
    {
      text: 'Exclude from backup',
      icon: richIcon('material-symbols-hide-source-outline-rounded'),
      menuItemProps: {
        disabled:
          !isAuthorized('update:exclude_resource') ||
          entities.every(
            (entity) => entity.backupStatus === BackupStatus.ExcludedFromBackup
          ),
        onClick: () => setExcludeFromBackupDialogOpen(true),
      },
    },
    {
      text: 'Cancel backup exclusion',
      icon: richIcon('material-symbols-add-circle-outline'),
      menuItemProps: {
        disabled:
          !isAuthorized('update:exclude_resource') ||
          entities.some(
            (entity) => entity.backupStatus !== BackupStatus.ExcludedFromBackup
          ),
        onClick: () => setCancelBackupExclusionsDialogOpen(true),
      },
    },
    // { divider: true },
    // { text: 'Manage violations' },
    {
      text: 'AUTO-CLASSIFICATIONS',
      groupHeader: true,
    },
    {
      text: containedItemText('Pause and override'),
      icon: richIcon('mdi-stop-circle-outline'),
      subMenu: [
        {
          text: 'Data classes',
          menuItemProps: {
            disabled: !isAuthorized('update:data_classification'),
            onClick: () => setDataClassesDiagOpen(true),
          },
        },
        {
          text: 'Environment',
          menuItemProps: {
            disabled: !isAuthorized('update:environment_classification'),
            onClick: () => setEnvDiagOpen(true),
          },
        },
        {
          text: 'Applications',
          menuItemProps: {
            disabled: !isAuthorized('update:apps_classification'),
            onClick: () => setAppDialogOpen(true),
          },
        },
      ],
    },
    {
      text: containedItemText('Resume'),
      icon: richIcon('material-symbols-motion-play-outline-rounded'),
      subMenu: [
        {
          text: 'Data classes',
          menuItemProps: {
            disabled: !isAuthorized('update:data_classification'),
            onClick: () => setResumeDataClassesDialogOpen(true),
          },
        },
        {
          text: 'Environment',
          menuItemProps: {
            disabled: !isAuthorized('update:environment_classification'),
            onClick: () => setResumeEnvDialogOpen(true),
          },
        },
        {
          text: 'Applications',
          menuItemProps: {
            disabled: !isAuthorized('update:apps_classification'),
            onClick: () => setResumeAppsDialogOpen(true),
          },
        },
      ],
    },
  ];

  return (
    <>
      <OptionMenu
        options={actions}
        Component={forwardRef<HTMLButtonElement>((props, ref) => (
          <Button
            className='h-[32px] p-[4px] pr-[12px]'
            variant='outlined'
            ref={ref}
            {...props}
          >
            <Icon iconClass='material-symbols-more-horiz mr-[8px]' />
            Actions
          </Button>
        ))}
      />
      {assignDialogOpen && (
        <InventoryBulkPolicyAssignmentDialog
          resourceIds={entities.map((entity) => entity.id)}
          onClose={() => setAssignDialogOpen(false)}
        />
      )}
      {dataClassesDiagOpen && (
        <DataClassEditor
          open={true}
          onClose={() => setDataClassesDiagOpen(false)}
          entities={entities}
        />
      )}
      {envDiagOpen && (
        <EnvironmentEditor
          open={true}
          onClose={() => setEnvDiagOpen(false)}
          entities={entities}
        />
      )}
      {appDialogOpen && (
        <AppsEditor
          open={true}
          onClose={() => setAppDialogOpen(false)}
          entities={entities}
        />
      )}
      {resumeAppsDialogOpen && (
        <RestartApplicationDetection
          open={true}
          onClose={() => setResumeAppsDialogOpen(false)}
          entities={entities}
        />
      )}
      {resumeDataClassesDialogOpen && (
        <RestartAutomaticDataClassClassification
          open={true}
          onClose={() => setResumeDataClassesDialogOpen(false)}
          entities={entities}
        />
      )}
      {resumeEnvDialogOpen && (
        <RestartAutomaticEnvironmentDetection
          open={true}
          onClose={() => setResumeEnvDialogOpen(false)}
          entities={entities}
        />
      )}
      {excludeFromBackupDialogOpen && (
        <InventoryBulkActionDialog
          title='Exclude from backup'
          description="Eon doesn't back up or report violations for excluded resources."
          onCancel={() => setExcludeFromBackupDialogOpen(false)}
          onConfirm={excludeFromBackup}
          buttonIconClass='material-symbols-hide-source-outline-rounded'
          buttonTitle='Exclude'
        />
      )}

      {cancelBackupExclusionsDialogOpen && (
        <InventoryBulkActionDialog
          title='Cancel backup exclusions'
          description='Eon will manage the selected resources using the applicable backup policies and controls.'
          onCancel={() => setCancelBackupExclusionsDialogOpen(false)}
          onConfirm={cancelBackupExclusions}
          buttonIconClass='material-symbols-add-circle-outline'
          buttonTitle='Cancel exclusions'
          cancelButtonTitle='Never mind'
        />
      )}
    </>
  );
}
